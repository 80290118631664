export function collectFormData(formElement) {
    const formData = {};
    const formElements = formElement.querySelectorAll('input, select, textarea');

    formElements.forEach((element) => {
        const elementType = element.type;
        const elementName = element.name;

        if (elementType === 'radio') {
            if (element.checked) {
                formData[elementName] = element.value;
            } else if (!formData[elementName]) {
                formData[elementName] = '';
            }
        } else if (elementType === 'checkbox') {
            if (!formData[elementName]) {
                formData[elementName] = [];
            }

            if (element.checked) {
                formData[elementName].push(element.value);
            }
        } else if (elementType === 'hidden') {
            return;
        } else {
            formData[elementName] = element.value;
        }
    });

    return formData;
}

export function collectDataAttributes(element, prefix) {
    const attributes = element.attributes;
    const extractedAttributes = {};

    for (let i = 0; i < attributes.length; i++) {
        const attributeName = attributes[i].name;
        const attributeValue = attributes[i].value;

        if (attributeName.startsWith(prefix)) {
            const cleanAttributeName = attributeName.substring(prefix.length);
            extractedAttributes[cleanAttributeName] = attributeValue;
        }
    }

    return extractedAttributes;
}
