import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from './globalEventProperties';

export const onSubmitEvents = () => {
    const forms = document.querySelectorAll('[data-mixpanel-form]');
    if (!forms.length) return;

    [...forms].forEach((form) => {
        form.addEventListener('submit', function () {
            // Validation classes are not added immediately, so setTimeout is used here
            setTimeout(() => {
                const props = cloneDeep(globalEventProperties);
                const event = this.dataset.mixpanelForm;
                const errorsElems = this.querySelectorAll(
                    '[data-mixpanel-error].is-error, [data-mixpanel-error].is-visible',
                );
                const errorPhones = this.querySelectorAll('[data-error-phone].error');
                const email = this.querySelector('[data-mixpanel-email]');
                const username = this.querySelector('[data-mixpanel-username]');
                const password = this.querySelector('[data-mixpanel-password]');
                const checkbox = this.querySelector('[data-checked-input]');

                const phone = this.querySelector('[data-mixpanel-phone]');
                const url = this.querySelector('[data-mixpanel-url]');
                const errorPhone = this.dataset.errorPhone;

                if (this.dataset.mixpanelButtonText) {
                    props.button_name = this.dataset.mixpanelButtonText;
                }

                if (this.dataset.mixpanelButtonLocation) {
                    props.button_location = this.dataset.mixpanelButtonLocation;
                }

                if (this.dataset.mixpanelButtonColor) {
                    props.button_color = this.dataset.mixpanelButtonColor;
                }

                if (errorsElems.length) {
                    let errorText = '';

                    errorsElems.forEach((errorElem) => {
                        errorText = `${errorText ? errorText + ';' : ''} ${errorElem.textContent.trim()}`;
                    });

                    props.form_validation = errorText;
                }

                if (errorPhones.length) {
                    let errorText = '';

                    errorPhones.forEach((errorElem) => {
                        errorText = `${errorText ? errorText + ';' : ''} ${errorElem.textContent.trim()}`;
                    });

                    props.form_validation = errorText;
                }

                if (this.classList.contains('error')) {
                    if (errorPhone) {
                        props.form_validation = errorPhone;
                    }
                }

                if (email) {
                    props.form_value = email.value.trim();

                    if (checkbox && !checkbox.checked) {
                        props.form_value_password = password.value.trim();
                    }

                    mixpanel.people.set({
                        $email: email.value.trim(),
                    });
                }

                if (username) {
                    props.form_value = username.value.trim();
                }

                if (phone) {
                    props.form_value = phone.value.trim();
                }

                if (url) {
                    props.form_value = url.value.trim();
                }

                if (username) {
                    props.form_value = username.value.trim();
                }

                mixpanel.track(event, props);
            }, 100);
        });
    });
};
