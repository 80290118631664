export function lazyLoadAnalytics(callback) {
    let isGtmCodeExecuted = false;
    const eventsToHandle = [
        'click',
        'dblclick',
        'mousedown',
        'keydown',
        'scroll',
        'mousemove',
        'touchstart',
        'orientationchange',
        'pagehide',
        'beforeunload',
        'unload',
    ];

    eventsToHandle.forEach((event) => {
        document.addEventListener(event, eventHandler, { once: true });
    });

    function eventHandler(e) {
        if (!isGtmCodeExecuted) {
            callback();
            isGtmCodeExecuted = true;
        }
        eventsToHandle.forEach((event) => {
            e.currentTarget.removeEventListener(event, eventHandler);
        });
    }
}
