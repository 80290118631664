import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from './globalEventProperties';

export const onChangeEvents = () => {
    const inputs = document.querySelectorAll('[data-mixpanel-change-field]');
    if (!inputs.length) return;

    [...inputs].forEach((input) => {
        input.addEventListener('change', function () {
            const props = cloneDeep(globalEventProperties);
            props.input_value = this.value;
            props.input_class = this.dataset.mixpanelChangeField;
            mixpanel.track('type_input', props);
        });
    });
};
