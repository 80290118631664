import { lazyLoadAnalytics } from '../../../../Common/Resources/src_front/js/components/lazyLoadAnalytics';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';
import { gtm } from './components/analytics/gtm';
import { fbPixel } from './components/analytics/fb-pixel';
import { tiktokPixel } from './components/analytics/tiktok-pixel';
import { twitter } from './components/analytics/twitter';
import { bingAds } from './components/analytics/bing-ads';
import { pushengage } from './components/analytics/pushengage';

document.addEventListener('DOMContentLoaded', function () {
    lazyLoadAnalytics(gtm);
    lazyLoadAnalytics(fbPixel);
    lazyLoadAnalytics(tiktokPixel);
    lazyLoadAnalytics(twitter);
    lazyLoadAnalytics(bingAds);
    lazyLoadAnalytics(pushengage);
    initMixpannel();
});
