import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from './globalEventProperties';

export const onLoadEvents = () => {
    const props = cloneDeep(globalEventProperties);

    mixpanel.track('page_view', props);

    if (document.querySelector('[data-500-error-page]')) {
        mixpanel.track('shop_500_error', props);
    }

    if (document.querySelector('[data-404-error-page]')) {
        mixpanel.track('shop_404_error', props);
    }
};
