import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from './globalEventProperties';

export const onClickEvents = () => {
    // VARS
    const elements = document.querySelectorAll('[data-mixpanel-click]');
    if (!elements.length) return;

    // INITS
    const setClickOnPushInterval = setInterval(setClickOnPush, 1000);

    // LISTENERS
    elements.forEach((element) => {
        element.addEventListener('click', function () {
            const props = cloneDeep(globalEventProperties);

            if (this.dataset.mixpanelButtonText) {
                props.button_name = this.dataset.mixpanelButtonText;
            }

            if (this.dataset.mixpanelButtonLocation) {
                props.button_location = this.dataset.mixpanelButtonLocation;
            }

            if (this.dataset.mixpanelButtonColor) {
                props.button_color = this.dataset.mixpanelButtonColor;
            }

            if (this.dataset.mixpanelPlanType) {
                props.plan_type = this.dataset.mixpanelPlanType;
            }

            if (this.dataset.mixpanelButtonDevice) {
                props.target_device = this.dataset.mixpanelButtonDevice;
            }

            if (this.dataset.mixpanelButtonLandingLanguage) {
                props.landing_language = this.dataset.mixpanelButtonLandingLanguage;
            }

            // umobix email-hacking.html
            if (this.hasAttribute('data-mixpanel-click-hack')) {
                const email = document.querySelector('[data-mixpanel-change-field="email-login"]');
                const emailDomain = document.querySelector('[data-mailbox-value]');
                const emailError = document.querySelector('[data-input-error]');

                props.form_value = email.value.trim() + emailDomain.textContent.trim();

                if (!emailError.classList.contains('hide')) {
                    props.form_validation = emailError.textContent.trim();
                }
            }

            mixpanel.track(this.dataset.mixpanelClick, props);
        });
    });

    // FUNCTIONS
    function setClickOnPush() {
        const bellPush = document.querySelector('#pe-widget-bell');

        if (bellPush) {
            const props = cloneDeep(globalEventProperties);
            clearInterval(setClickOnPushInterval);

            bellPush.addEventListener('click', function () {
                mixpanel.track('subscribe_to_notification', props);
            });
        }
    }
};
