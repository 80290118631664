export function getUrlParameters() {
    const urlObj = new URL(window.location);
    const params = new URLSearchParams(urlObj.search);

    const splitVariant = params.get('split_variant');
    const splitGroup = params.get('split_group');
    const parameters = {};

    if (splitVariant !== null && splitGroup !== null) {
        parameters['split_variant'] = splitVariant;
        parameters['split_group'] = splitGroup;
    }

    return parameters;
}
