export function getDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('mobile') !== -1) {
        return 'mobile';
    } else if (userAgent.indexOf('tablet') !== -1) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}
